@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Atkinson Hyperlegible", sans-serif;
}

:focus {
  outline: none !important;
}

body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark body {
  @apply bg-very-dark-grey;
}

code {
  font-family: source-code-pro, "Oxygen", "Droid Sans", "Courier New", monospace;
}
