.react-calendar-heatmap .color-scale-0 {
  fill: var(--heatmap-color-scale-0);
}
.react-calendar-heatmap .color-scale-1 {
  fill: var(--heatmap-color-scale-1);
}
.react-calendar-heatmap .color-scale-2 {
  fill: var(--heatmap-color-scale-2);
}
.react-calendar-heatmap .color-scale-3 {
  fill: var(--heatmap-color-scale-3);
}
.react-calendar-heatmap .color-scale-4 {
  fill: var(--heatmap-color-scale-4);
}
.react-calendar-heatmap .color-empty {
  fill: var(--heatmap-color-empty);
}