@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .mobile-safe-area {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.pulsating-halo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pulsating-halo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Adjust size of the halo */
  height: 100%; /* Adjust size of the halo */
  background-color: #f87171; /* Halo color */
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  animation: pulsate 1.5s infinite ease-out;
  transform: translate(-50%, -50%) scale(1); /* Adjust for perfect centering */
  transform-origin: center;
}
