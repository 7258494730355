.arrow-up::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #1a202c; /* Change this color to match the dark:bg-dark-grey color */
  }
  
  .dark .arrow-up::before {
    border-bottom-color: #14532d; /* Change this color to match the dark:bg-green-900 color */
  }